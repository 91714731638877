<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="9"
        viewBox="0 0 39 9"
        fill="none"
    >
        <path
            d="M0.510254 4.03857C0.234112 4.03857 0.0102539 4.26243 0.0102539 4.53857C0.0102539 4.81472 0.234112 5.03857 0.510254 5.03857V4.03857ZM37.8638 4.89213C38.0591 4.69687 38.0591 4.38028 37.8638 4.18502L34.6818 1.00304C34.4866 0.807778 34.17 0.807778 33.9747 1.00304C33.7795 1.1983 33.7795 1.51488 33.9747 1.71015L36.8031 4.53857L33.9747 7.367C33.7795 7.56226 33.7795 7.87885 33.9747 8.07411C34.17 8.26937 34.4866 8.26937 34.6818 8.07411L37.8638 4.89213ZM0.510254 5.03857H37.5103V4.03857H0.510254V5.03857Z"
            fill="currentColor"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
